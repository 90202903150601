import React, { useState } from "react";
import { Button } from "@mui/material";
import { DialogExternalLink } from "components/common";

const ButtonExternalLink = ({ href, target, children, ...rest }) => {
  const [openDialog, setOpenDialog] = useState(false);
  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleLinkClick = (event) => {
    if (target === "_blank" || !href.startsWith(window.location.origin)) {
      setOpenDialog(true);
      event.preventDefault();
    }
  };

  return (
    <>
      <Button
        href={href}
        target={target || "_self"}
        onClick={handleLinkClick}
        {...rest}
      >
        {children}
      </Button>
      <DialogExternalLink
        open={openDialog}
        onClose={handleCloseDialog}
        href={href}
      />
    </>
  );
};

export default ButtonExternalLink;
