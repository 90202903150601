import React, { useState, useEffect } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useSearchParams, Link as RouterLink } from "react-router-dom";
import axios from "axios";
import {
  ArrowLongLeftIcon,
  ArrowLongRightIcon,
} from "@heroicons/react/24/outline";
import {
  Button,
  Typography,
  Box,
  Grid,
  Stack,
  FormControl,
  InputLabel,
  FilledInput,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import { Stepper, FormErrorMessage, LinkText } from "components/common";
import config from "utils/config";
import SignUpTemplate from "pages/templates/SignUpTemplate";
import LoginTemplate from "pages/templates/LoginTemplate";
import useUserTracking from "utils/useTracking";

const groupByFirstLetter = (arrString) => {
  const groups = {};
  for (const word of arrString) {
    const firstLetter = word[0];
    if (!groups[firstLetter]) {
      groups[firstLetter] = [];
    }
    groups[firstLetter].push(word);
  }
  return groups;
};

const getLetters = (startLetter, endLetter) => {
  const letters = [];

  // Check if the parameters are valid letters.
  if (!/^[a-zA-Z]$/.test(startLetter) || !/^[a-zA-Z]$/.test(endLetter)) {
    return letters;
  }

  // Convert the parameters to uppercase.
  startLetter = startLetter.toUpperCase();
  endLetter = endLetter.toUpperCase();

  // Calculate the difference between the start and end letters.
  const difference = endLetter.charCodeAt() - startLetter.charCodeAt();

  // Create a list of letters from startLetter to endLetter, inclusive.
  for (let i = 0; i <= difference; i++) {
    letters.push(String.fromCharCode(startLetter.charCodeAt() + i));
  }

  return letters;
};

const SignUp = () => {
  const [searchParams] = useSearchParams();
  const userEmail = searchParams.get("email");

  useUserTracking({
    page: "new-account",
  });

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [currentStep, setCurrentStep] = useState(1);

  // ------------------------------------------------------------------------------------
  // GET SPECIALITIES

  const [specialities, setSpecialities] = useState([]);
  const [groupedSpecialities, setGroupedSpecialities] = useState({});

  useEffect(() => {
    const getSpecialities = async () => {
      try {
        const response = await axios.get(
          `${config.API_URL}/register/get-available-specialties`
        );

        setSpecialities(response.data.valid_specialties);
        setGroupedSpecialities(
          groupByFirstLetter(response.data.valid_specialties)
        );
      } catch (err) {
        setError(config.ERRORS.API_ERROR_SIGNUP);
      }
    };
    getSpecialities();
  }, []);

  // ------------------------------------------------------------------------------------
  // USER EMAIL, LASTNAME AND FIRSTNAME

  const handleSubmitUserSignUp = async (values) => {
    // console.log("VALUES", values);
    window.scrollTo(0, 0);
    setError("");
    setCurrentStep(2);
  };

  const formikUserSignUp = useFormik({
    initialValues: {
      email: userEmail || "", //test@test.com
      last_name: "",
      first_name: "",
      mobile_phone: "",
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .required("Adresa de e-mail este obligatorie.")
        .email("Adresa introdusă nu pare a fi corectă."),
      last_name: Yup.string().required("Numele este obligatoriu."),
      first_name: Yup.string().required("Prenumele este obligatoriu."),
      mobile_phone: Yup.string()
        .required("Telefonul mobil este obligatoriu.")
        .matches(/^\+?\d+$/, "Numărul de telefon este introdus incorect."),
    }),
    onSubmit: handleSubmitUserSignUp,
  });

  const filterPhoneNumber = (value) => {
    return value.replace(/[^?+0-9]|(?!^)\+/g, "");
  };

  // ------------------------------------------------------------------------------------
  // SPECIALITIES

  const [chosenSpecialities, setChosenSpecialities] = useState({});
  // console.log(chosenSpecialities);

  const SpecialityCheckbox = ({ letter }) => {
    return specialities.length > 0 &&
      groupedSpecialities[letter]?.length > 0 ? (
      <Box marginBottom={1}>
        <Typography variant="body3" color="primary" sx={{ fontWeight: 300 }}>
          {letter}
        </Typography>
        {groupedSpecialities[letter].map((speciality, index) => (
          <Box key={`${speciality}-${index}`}>
            <FormControlLabel
              control={
                <Checkbox
                  size="small"
                  checked={chosenSpecialities[speciality]}
                  onChange={(e) =>
                    setChosenSpecialities({
                      ...chosenSpecialities,
                      [speciality]: e.target.checked,
                    })
                  }
                  inputProps={{ "aria-label": "controlled" }}
                />
              }
              label={speciality}
              componentsProps={{
                typography: {
                  variant: "body2",
                  color: "primary",
                },
              }}
            />
          </Box>
        ))}
      </Box>
    ) : null;
  };

  // ------------------------------------------------------------------------------------
  // USER ORGANIZATION

  const handleSubmitUserOrganization = async (values) => {
    // console.log("VALUES", values);
    setError("");
    setCurrentStep(4);
    window.scrollTo(0, 0);
  };

  const formikUserOrganization = useFormik({
    initialValues: {
      workplace: "",
      address: "",
      city: "",
      county: "",
      fixed_phone: "",
    },
    validationSchema: Yup.object({
      workplace: Yup.string().required("Denumirea este obligatorie."),
      address: Yup.string().required("Adresa este obligatorie."),
      city: Yup.string().required("Localitatea este obligatorie."),
      county: Yup.string().required("Județul/sectorul este obligatoriu."),
      fixed_phone: Yup.string()
        .required("Telefonul de la locul de muncă este obligatoriu.")
        .matches(/^\+?\d+$/, "Numărul de telefon este introdus incorect."),
    }),
    onSubmit: handleSubmitUserOrganization,
  });

  // ------------------------------------------------------------------------------------
  // USER ACCEPTANCE

  const handleUserAcceptance = async (values) => {
    // console.log("VALUES", values);
    setLoading(true);

    const chosenSpecialitiesArr = Object.keys(chosenSpecialities).filter(
      (key) => chosenSpecialities[key] === true
    );

    try {
      const response = await axios.post(`${config.API_URL}/register`, {
        request_last_name: formikUserSignUp.values.last_name,
        request_first_name: formikUserSignUp.values.first_name,
        request_mobile_phone: formikUserSignUp.values.mobile_phone,
        request_email: formikUserSignUp.values.email,
        request_specialty: chosenSpecialitiesArr,
        request_workplace: formikUserOrganization.values.workplace,
        request_address: formikUserOrganization.values.address,
        request_county: formikUserOrganization.values.county,
        request_city: formikUserOrganization.values.city,
        request_fixed_phone: formikUserOrganization.values.fixed_phone,
        request_agreement1: formikUserAcceptance.values.acceptGDPR ? 1 : 0,
        request_agreement2: formikUserAcceptance.values.acceptTerms ? 1 : 0,
        request_agreement3: formikUserAcceptance.values.acceptConfidentiality
          ? 1
          : 0,
        request_agreement4: formikUserAcceptance.values.acceptMarketing ? 1 : 0,
      });

      if (response.data.message === "Registration successful") {
        setError("");
        setCurrentStep(5);
        setLoading(false);
        window.scrollTo(0, 0);
      }
    } catch (err) {
      console.error(err);
      setError(
        `Există deja o cerere de creare cont cu adresa de e-mail ${formikUserSignUp.values.email}.`
      );

      /*
      if (
        err.response.status === 401 &&
        err.response.data.message === "Validation failed"
      ) {
        if (
          err.response.data.errors?.request_email.includes(
            "The request email has already been taken."
          )
        ) {
          setError(
            `Există deja o cerere de creare cont cu adresa de e-mail ${formikUserSignUp.values.email}.`
          );
        } else {
          const fieldsValidationError = Object.keys(err.response.data?.errors);
          const fieldsNiceName = {
            request_last_name: "Nume",
            request_first_name: "Prenume",
            request_mobile_phone: "Telefon mobil",
            request_email: "Email",
            request_specialty: "Specialitate",
            request_workplace: "Denumirea",
            request_address: "Adresa",
            request_county: "Localitatea",
            request_city: "Județ/Sector",
            request_fixed_phone: "Telefon loc de muncă",
            request_agreement1: "Nota de informare",
            request_agreement2: "Termenii și condițiile",
            request_agreement3: "Politica de confidențialitate",
            request_agreement4: "Acord de marketing",
          };
          const fieldsValidationErrorNice = fieldsValidationError.map(
            (field) => fieldsNiceName[field]
          );

          setError(
            `Informații incomplete în câmpurile: ${fieldsValidationErrorNice.join(
              ", "
            )}.`
          );
        }
      } else {
        setError(config.ERRORS.API_ERROR_SIGNUP);
      }
      */
    }
  };
  const formikUserAcceptance = useFormik({
    initialValues: {
      acceptGDPR: false,
      acceptTerms: false,
      acceptConfidentiality: false,
      acceptMarketing: false,
    },
    validationSchema: Yup.object({
      acceptGDPR: Yup.boolean().oneOf(
        [true],
        "Trebuie să acceptați nota de informare."
      ),
      acceptTerms: Yup.boolean().oneOf(
        [true],
        "Trebuie să acceptați termenii și condițiile."
      ),
      acceptConfidentiality: Yup.boolean().oneOf(
        [true],
        "Trebuie să acceptați politica de confidențialitate."
      ),
      acceptMarketing: Yup.boolean().oneOf(
        [true],
        "Trebuie să acceptați acordul de marketing."
      ),
    }),
    onSubmit: handleUserAcceptance,
  });

  const onChangeCheckbox = (e, checkboxName) => {
    formikUserAcceptance.setFieldValue(checkboxName, e.target.checked);
    window.setTimeout(() => {
      formikUserAcceptance.setTouched({
        ...formikUserAcceptance.touched,
        [checkboxName]: true,
      });
      formikUserAcceptance.validateField(checkboxName);
    }, 10);
  };

  return currentStep < 5 ? (
    <SignUpTemplate>
      <Box width="100%">
        <Box
          marginBottom={4}
          maxWidth={900}
          sx={{
            marginLeft: {
              xs: -1.5,
              sm: "auto",
            },
            marginRight: {
              xs: -2.25,
              sm: "auto",
            },
          }}
        >
          <Stepper
            activeStep={currentStep - 1}
            steps={[
              "Informații personale",
              "Specialitatea",
              "Informații loc de muncă",
              "Acord",
            ]}
          />
        </Box>
        {currentStep === 1 && (
          <Box maxWidth={404} paddingTop={4} marginX="auto">
            <form onSubmit={formikUserSignUp.handleSubmit}>
              <FormControl
                fullWidth
                variant="filled"
                sx={{
                  marginBottom: 3,
                }}
              >
                <InputLabel htmlFor="email">
                  Adresa de e-mail a contului
                </InputLabel>
                <FilledInput
                  id="email"
                  name="email"
                  autoComplete="email"
                  // disabled={userEmail !== null}
                  value={formikUserSignUp.values.email}
                  onChange={formikUserSignUp.handleChange}
                  onBlur={formikUserSignUp.handleBlur}
                  error={
                    formikUserSignUp.touched.email &&
                    Boolean(formikUserSignUp.errors.email)
                  }
                />
                {formikUserSignUp.touched.email && (
                  <FormErrorMessage>
                    {formikUserSignUp.errors.email}
                  </FormErrorMessage>
                )}
              </FormControl>
              <FormControl
                fullWidth
                variant="filled"
                sx={{
                  marginBottom: 3,
                }}
              >
                <InputLabel htmlFor="last_name">Nume</InputLabel>
                <FilledInput
                  id="last_name"
                  name="last_name"
                  autoComplete="given-name"
                  value={formikUserSignUp.values.last_name}
                  onChange={formikUserSignUp.handleChange}
                  onBlur={formikUserSignUp.handleBlur}
                  error={
                    formikUserSignUp.touched.last_name &&
                    Boolean(formikUserSignUp.errors.last_name)
                  }
                />
                {formikUserSignUp.touched.last_name && (
                  <FormErrorMessage>
                    {formikUserSignUp.errors.last_name}
                  </FormErrorMessage>
                )}
              </FormControl>
              <FormControl
                fullWidth
                variant="filled"
                sx={{
                  marginBottom: 3,
                }}
              >
                <InputLabel htmlFor="first_name">Prenume</InputLabel>
                <FilledInput
                  id="first_name"
                  name="first_name"
                  autoComplete="family-name"
                  value={formikUserSignUp.values.first_name}
                  onChange={formikUserSignUp.handleChange}
                  onBlur={formikUserSignUp.handleBlur}
                  error={
                    formikUserSignUp.touched.first_name &&
                    Boolean(formikUserSignUp.errors.first_name)
                  }
                />
                {formikUserSignUp.touched.first_name && (
                  <FormErrorMessage>
                    {formikUserSignUp.errors.first_name}
                  </FormErrorMessage>
                )}
              </FormControl>
              <FormControl
                fullWidth
                variant="filled"
                sx={{
                  marginBottom: 3,
                }}
              >
                <InputLabel htmlFor="mobile_phone">Telefon mobil</InputLabel>
                <FilledInput
                  id="mobile_phone"
                  name="mobile_phone"
                  autoComplete="phone"
                  value={formikUserSignUp.values.mobile_phone}
                  onChange={(e) => {
                    e.target.value = filterPhoneNumber(e.target.value);
                    formikUserSignUp.handleChange(e);
                  }}
                  onBlur={formikUserSignUp.handleBlur}
                  error={
                    formikUserSignUp.touched.mobile_phone &&
                    Boolean(formikUserSignUp.errors.mobile_phone)
                  }
                />
                {formikUserSignUp.touched.mobile_phone && (
                  <FormErrorMessage>
                    {formikUserSignUp.errors.mobile_phone}
                  </FormErrorMessage>
                )}
              </FormControl>
              <Box marginTop={4} marginBottom={6} textAlign="center">
                <Button
                  color="primary"
                  disabled={loading}
                  variant="contained"
                  endIcon={<ArrowLongRightIcon width={20} />}
                  type="submit"
                >
                  Următorul pas
                </Button>
              </Box>
              <FormErrorMessage>{error}</FormErrorMessage>
            </form>
          </Box>
        )}
        {currentStep === 2 && specialities.length > 0 && (
          <>
            <Grid
              container
              columnSpacing={7}
              alignItems="stretch"
              sx={{ marginBottom: 4, maxWidth: 1200, marginX: "auto" }}
            >
              <Grid item sm={12} md={6} lg={3}>
                {getLetters("A", "C").map((firstLetter) => (
                  <SpecialityCheckbox key={firstLetter} letter={firstLetter} />
                ))}
              </Grid>
              <Grid item sm={12} md={6} lg={3}>
                {getLetters("D", "H").map((firstLetter) => (
                  <SpecialityCheckbox key={firstLetter} letter={firstLetter} />
                ))}
              </Grid>
              <Grid item sm={12} md={6} lg={3}>
                {getLetters("I", "N").map((firstLetter) => (
                  <SpecialityCheckbox key={firstLetter} letter={firstLetter} />
                ))}
              </Grid>
              <Grid item sm={12} md={6} lg={3}>
                {getLetters("O", "Z").map((firstLetter) => (
                  <SpecialityCheckbox key={firstLetter} letter={firstLetter} />
                ))}
              </Grid>
            </Grid>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              marginBottom={6}
            >
              <Button
                variant="text"
                startIcon={<ArrowLongLeftIcon width={20} />}
                onClick={() => setCurrentStep(currentStep - 1)}
              >
                Înapoi
              </Button>
              <Button
                variant="contained"
                color="primary"
                endIcon={<ArrowLongRightIcon width={20} />}
                disabled={
                  Object.values(chosenSpecialities).find(
                    (item) => item === true
                  ) === undefined
                }
                onClick={() => {
                  setCurrentStep(currentStep + 1);
                  window.scrollTo(0, 0);
                }}
              >
                Următorul pas
              </Button>
            </Stack>
          </>
        )}
        {currentStep === 3 && (
          <Box paddingTop={4}>
            <form onSubmit={formikUserOrganization.handleSubmit}>
              <Box maxWidth={404} marginX="auto">
                <FormControl
                  fullWidth
                  variant="filled"
                  sx={{
                    marginBottom: 3,
                  }}
                >
                  <InputLabel htmlFor="workplace">Denumirea</InputLabel>
                  <FilledInput
                    id="workplace"
                    name="workplace"
                    autoComplete="organization"
                    value={formikUserOrganization.values.workplace}
                    onChange={formikUserOrganization.handleChange}
                    onBlur={formikUserOrganization.handleBlur}
                    error={
                      formikUserOrganization.touched.workplace &&
                      Boolean(formikUserOrganization.errors.workplace)
                    }
                  />
                  {formikUserOrganization.touched.workplace && (
                    <FormErrorMessage>
                      {formikUserOrganization.errors.workplace}
                    </FormErrorMessage>
                  )}
                </FormControl>
                <FormControl
                  fullWidth
                  variant="filled"
                  sx={{
                    marginBottom: 3,
                  }}
                >
                  <InputLabel htmlFor="address">Adresa</InputLabel>
                  <FilledInput
                    id="address"
                    name="address"
                    autoComplete="organization-address"
                    value={formikUserOrganization.values.address}
                    onChange={formikUserOrganization.handleChange}
                    onBlur={formikUserOrganization.handleBlur}
                    error={
                      formikUserOrganization.touched.address &&
                      Boolean(formikUserOrganization.errors.address)
                    }
                  />
                  {formikUserOrganization.touched.address && (
                    <FormErrorMessage>
                      {formikUserOrganization.errors.address}
                    </FormErrorMessage>
                  )}
                </FormControl>
                <FormControl
                  fullWidth
                  variant="filled"
                  sx={{
                    marginBottom: 3,
                  }}
                >
                  <InputLabel htmlFor="city">Localitatea</InputLabel>
                  <FilledInput
                    id="city"
                    name="city"
                    autoComplete="organization-city"
                    value={formikUserOrganization.values.city}
                    onChange={formikUserOrganization.handleChange}
                    onBlur={formikUserOrganization.handleBlur}
                    error={
                      formikUserOrganization.touched.city &&
                      Boolean(formikUserOrganization.errors.city)
                    }
                  />
                  {formikUserOrganization.touched.city && (
                    <FormErrorMessage>
                      {formikUserOrganization.errors.city}
                    </FormErrorMessage>
                  )}
                </FormControl>
                <FormControl
                  fullWidth
                  variant="filled"
                  sx={{
                    marginBottom: 3,
                  }}
                >
                  <InputLabel htmlFor="county">Județ/Sector</InputLabel>
                  <FilledInput
                    id="county"
                    name="county"
                    autoComplete="organization-county"
                    value={formikUserOrganization.values.county}
                    onChange={formikUserOrganization.handleChange}
                    onBlur={formikUserOrganization.handleBlur}
                    error={
                      formikUserOrganization.touched.county &&
                      Boolean(formikUserOrganization.errors.county)
                    }
                  />
                  {formikUserOrganization.touched.county && (
                    <FormErrorMessage>
                      {formikUserOrganization.errors.county}
                    </FormErrorMessage>
                  )}
                </FormControl>
                <FormControl
                  fullWidth
                  variant="filled"
                  sx={{
                    marginBottom: 3,
                  }}
                >
                  <InputLabel htmlFor="fixed_phone">
                    Telefon loc de muncă
                  </InputLabel>
                  <FilledInput
                    id="fixed_phone"
                    name="fixed_phone"
                    autoComplete="organization-phone"
                    value={formikUserOrganization.values.fixed_phone}
                    onChange={(e) => {
                      e.target.value = filterPhoneNumber(e.target.value);
                      formikUserOrganization.handleChange(e);
                    }}
                    onBlur={formikUserOrganization.handleBlur}
                    error={
                      formikUserOrganization.touched.fixed_phone &&
                      Boolean(formikUserOrganization.errors.fixed_phone)
                    }
                  />
                  {formikUserOrganization.touched.fixed_phone && (
                    <FormErrorMessage>
                      {formikUserOrganization.errors.fixed_phone}
                    </FormErrorMessage>
                  )}
                </FormControl>
              </Box>
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
                marginBottom={6}
              >
                <Button
                  variant="text"
                  startIcon={<ArrowLongLeftIcon width={20} />}
                  onClick={() => setCurrentStep(currentStep - 1)}
                >
                  Înapoi
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  endIcon={<ArrowLongRightIcon width={20} />}
                  disabled={loading}
                  type="submit"
                >
                  Următorul pas
                </Button>
              </Stack>
            </form>
          </Box>
        )}
        {currentStep === 4 && (
          <>
            <Box maxWidth={900} marginX="auto" paddingTop={6} marginBottom={6}>
              <Typography>
                Pentru a crea contul este necesar acordul dumneavoastră pentru:
              </Typography>
            </Box>
            <form onSubmit={formikUserAcceptance.handleSubmit}>
              <Box maxWidth={900} marginX="auto" marginBottom={4}>
                <Box marginBottom={3}>
                  <FormControlLabel
                    name="acceptGDPR"
                    control={
                      <Checkbox
                        size="small"
                        checked={formikUserAcceptance.values.acceptGDPR}
                        onChange={(e) => onChangeCheckbox(e, "acceptGDPR")}
                      />
                    }
                    label={
                      <Typography variant="body2">
                        Am citit{" "}
                        <LinkText href={config.DOCUMENTS.GDPR} target="_blank">
                          nota de informare
                        </LinkText>{" "}
                        cu privire la prelucrarea datelor cu caracter personal
                      </Typography>
                    }
                  />
                  {formikUserAcceptance.touched.acceptGDPR && (
                    <FormErrorMessage>
                      {formikUserAcceptance.errors.acceptGDPR}
                    </FormErrorMessage>
                  )}
                </Box>
                <Box marginBottom={3}>
                  <FormControlLabel
                    name="acceptTerms"
                    control={
                      <Checkbox
                        size="small"
                        checked={formikUserAcceptance.values.acceptTerms}
                        onChange={(e) => onChangeCheckbox(e, "acceptTerms")}
                      />
                    }
                    label={
                      <Typography variant="body2">
                        Sunt de acord cu{" "}
                        <LinkText href={config.DOCUMENTS.TERMS} target="_blank">
                          termenii și condițiile
                        </LinkText>{" "}
                        site-ului aznextgen.ro
                      </Typography>
                    }
                  />
                  {formikUserAcceptance.touched.acceptTerms && (
                    <FormErrorMessage>
                      {formikUserAcceptance.errors.acceptTerms}
                    </FormErrorMessage>
                  )}
                </Box>
                <Box marginBottom={3}>
                  <FormControlLabel
                    name="acceptConfidentiality"
                    control={
                      <Checkbox
                        size="small"
                        checked={
                          formikUserAcceptance.values.acceptConfidentiality
                        }
                        onChange={(e) =>
                          onChangeCheckbox(e, "acceptConfidentiality")
                        }
                      />
                    }
                    label={
                      <Typography variant="body2">
                        Sunt de acord cu standardele interne privind{" "}
                        <LinkText
                          href={config.DOCUMENTS.CONFIDENTIALITY}
                          target="_blank"
                        >
                          politica de confidențialitate
                        </LinkText>{" "}
                        a site-ului aznextgen.ro
                      </Typography>
                    }
                  />
                  {formikUserAcceptance.touched.acceptConfidentiality && (
                    <FormErrorMessage>
                      {formikUserAcceptance.errors.acceptConfidentiality}
                    </FormErrorMessage>
                  )}
                </Box>
                <Box marginBottom={3}>
                  <FormControlLabel
                    name="acceptMarketing"
                    control={
                      <Checkbox
                        size="small"
                        checked={formikUserAcceptance.values.acceptMarketing}
                        onChange={(e) => onChangeCheckbox(e, "acceptMarketing")}
                      />
                    }
                    // onBlur={formikUserAcceptance.handleBlur}
                    onChange={formikUserAcceptance.handleChange}
                    label="Sunt de acord să primesc mesaje de marketing de la compania AstraZeneca prin e-mail și SMS"
                  />
                  {formikUserAcceptance.touched.acceptMarketing && (
                    <FormErrorMessage>
                      {formikUserAcceptance.errors.acceptMarketing}
                    </FormErrorMessage>
                  )}
                </Box>
              </Box>

              <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
                marginBottom={6}
              >
                <Button
                  variant="text"
                  startIcon={<ArrowLongLeftIcon width={20} />}
                  onClick={() => {
                    setError("");
                    setLoading(false);
                    setCurrentStep(currentStep - 1);
                  }}
                >
                  Înapoi
                </Button>
                <FormErrorMessage>{error}</FormErrorMessage>
                <Button
                  variant="contained"
                  color="primary"
                  endIcon={<ArrowLongRightIcon width={20} />}
                  disabled={loading}
                  type="submit"
                >
                  Următorul pas
                </Button>
              </Stack>
            </form>
          </>
        )}
      </Box>
    </SignUpTemplate>
  ) : (
    <LoginTemplate>
      <Box
        sx={{
          marginBottom: 7,
          textAlign: {
            xs: "center",
            md: "left",
          },
        }}
      >
        <Typography variant="h1" marginBottom={7}>
          Cererea a fost înregistrată
        </Typography>
        <Typography>Vă mulțumim!</Typography>
        <Typography>
          Un e-mail de confirmare a cererii Dumneavoastră a fost trimis către
          adresa {formikUserSignUp.values.email}.
        </Typography>
        <Typography>Vă rugăm să verificați și în folderul Spam.</Typography>
      </Box>
      <Box
        marginTop={4}
        sx={{
          textAlign: {
            xs: "center",
            md: "left",
          },
        }}
      >
        <Button
          variant="text"
          startIcon={<ArrowLongLeftIcon width={20} />}
          to="/login"
          sx={{ marginBottom: 2.5 }}
          component={RouterLink}
        >
          Înapoi la Autentificare
        </Button>
      </Box>
    </LoginTemplate>
  );
};

export default SignUp;
