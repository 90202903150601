import React, { useState, useContext, useEffect, useRef } from "react";
import { useNavigate, Link as RouterLink, useParams } from "react-router-dom";
import {
  Container,
  Button,
  Typography,
  Box,
  Grid,
  Stack,
  Link,
  Tabs,
  Tab,
  Tooltip,
} from "@mui/material";
// import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import {
  ArrowLongRightIcon,
  ArrowLongLeftIcon,
} from "@heroicons/react/24/outline";
import theme from "utils/theme";
import { scrollbarStyles } from "utils/config-design";
import { GetArticles, GetContentTherapeuticAreas } from "utils/api/getContent";
import {
  Icon,
  EventBox,
  ArticleBox,
  VideoBox,
  ButtonExternalLink,
} from "components/common";
import appContext from "utils/context";
import isFutureDate from "utils/isFutureDate";
import getImage from "utils/api/getImage";
import { articleContentSanitized } from "utils/content";
import useWindowAspectRatio from "utils/useWindowAspectRatio";
import useUserTracking from "utils/useTracking";
import Error404 from "pages/Error404";

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <Box
      role="tabpanel"
      hidden={value !== index}
      id={`product-tabpanel-${index}`}
      aria-labelledby={`product-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ paddingY: 2 }}>{children}</Box>}
    </Box>
  );
};

// TabPanel.propTypes = {
//   children: PropTypes.node,
//   index: PropTypes.number.isRequired,
//   value: PropTypes.number.isRequired,
// };

const a11yProps = (index) => {
  return {
    id: `product-tab-${index}`,
    "aria-controls": `product-tabpanel-${index}`,
    sx: {
      padding: theme.spacing(0.5, 1),
      minWidth: "auto",
      minHeight: 38,
      textTransform: "initial",
      color: "secondary.darker",
      fontWeight: 300,
      fontSize: "1rem",
      letterSpacing: "-0.025rem",
      "&.Mui-selected": {
        padding: theme.spacing(0.5, 1),
        color: "secondary.darker",
        fontWeight: 400,
      },
    },
  };
};

const ProductButton = ({ label, href }) => {
  return (
    <ButtonExternalLink
      fullWidth
      component={Link}
      href={href}
      target="_blank"
      variant="outlined"
      endIcon={
        <Box position="relative">
          <ArrowLongRightIcon width={24} />
          <Box
            sx={{
              position: "absolute",
              width: 18,
              height: 18,
              borderRadius: "50%",
              border: `1px solid ${"secondary.darker"}`,
              top: "50%",
              transform: "translateY(-50%)",
              right: "-4px",
            }}
          />
        </Box>
      }
      sx={{
        flex: 1,
        borderColor: "secondary.darker",
        height: 38,
        "& .MuiButton-endIcon": {
          transition: "all 200ms ease-in-out",
          overflow: "hidden",
          width: 0,
        },
        "&:hover": {
          borderColor: "secondary.darker",
          "& .MuiButton-endIcon": {
            width: 28,
          },
        },
        color: "#110720",
      }}
    >
      <Tooltip title={label}>
        <Box
          sx={{
            minWidth: { xs: "auto", sm: "fit-content", md: "auto" },
            maxWidth: "calc(100% - 30px)",
            whiteSpace: "nowrap",
            display: "block",
            overflow: "hidden",
            textOverflow: "ellipsis",
            textAlign: "center",
          }}
        >
          {label}
        </Box>
      </Tooltip>
    </ButtonExternalLink>
  );
};

const ProductInfo = () => {
  const {
    therapeuticAreaSlug,
    therapeuticAreaIndicationSlug,
    brandSlug,
    contentSlug,
  } = useParams();

  const {
    data: therapeuticAreasData,
    // isError,
    // isPending,
  } = GetContentTherapeuticAreas();
  const therapeuticArea = therapeuticAreasData
    ? therapeuticAreasData[therapeuticAreaSlug]
    : null;
  const therapeuticAreaIndication = therapeuticArea?.indications.find(
    (indication) => indication.slug === therapeuticAreaIndicationSlug
  );
  const product =
    therapeuticAreaIndication?.brands.find(
      (brand) => brand.slug === brandSlug
    ) || null;

  const { userTrack } = useUserTracking({
    page: brandSlug,
    therapeutic_area: therapeuticAreaSlug,
    therapeutic_area_indication: therapeuticAreaIndicationSlug,
    brand: brandSlug,
    content_type: contentSlug || "about",
  });

  const productCustomTabs = product?.tabs ?? null;
  const navigate = useNavigate();

  const [contentSlugs, setContentSlugs] = useState(null);
  useEffect(() => {
    let tabs;
    if (productCustomTabs) {
      const productCustomTabsSlugs = productCustomTabs.map((tab) => tab.slug);
      if (product.custom_tabs === 1) {
        tabs = [
          undefined,
          "articles",
          "videos",
          "events",
          ...productCustomTabsSlugs,
        ];
      } else {
        tabs = productCustomTabsSlugs;
      }
    } else {
      tabs = [undefined, "articles", "videos", "events"];
    }
    setContentSlugs(tabs);
    if (
      contentSlug !== "" &&
      tabs.findIndex((item) => item === contentSlug) === -1
    ) {
      navigate(
        `/therapeutic-areas/${therapeuticAreaSlug}/${therapeuticAreaIndicationSlug}/${brandSlug}`
      );
    }
    setProductTab(
      tabs.findIndex((item) => item === contentSlug) !== -1
        ? tabs.findIndex((item) => item === contentSlug)
        : 0
    );
  }, [productCustomTabs, contentSlug]); // eslint-disable-line react-hooks/exhaustive-deps

  // TABS
  const [productTab, setProductTab] = useState(null);
  const changeProductTab = (event, newTab) => {
    navigate(
      `/therapeutic-areas/${therapeuticAreaSlug}/${therapeuticAreaIndicationSlug}/${brandSlug}/${
        newTab !== 0 ? contentSlugs[newTab] : ""
      }`
    );
    userTrack({
      page: brandSlug,
      therapeutic_area: therapeuticAreaSlug,
      therapeutic_area_indication: therapeuticAreaIndicationSlug,
      brand: brandSlug,
      content_type: newTab !== 0 ? contentSlugs[newTab] : "about",
    });
    // setProductTab(newTab);
  };
  // useEffect(() => {
  //   setProductTab(contentSlugs.findIndex((item) => item === contentSlug) || 0);
  // }, [contentSlug]); // eslint-disable-line react-hooks/exhaustive-deps

  // CONTENT
  const {
    data: therapeuticAreasContent,
    // isErrorArticles,
    // isPendingArticles,
  } = GetArticles();

  const { serverTime, setIsWhiteContentPage } = useContext(appContext);
  useEffect(() => {
    setIsWhiteContentPage(true);

    return () => {
      setIsWhiteContentPage(false);
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const productEventsFuture = therapeuticAreasContent?.events?.filter(
    (event) =>
      isFutureDate(event.date, serverTime) &&
      event.brand_slug === brandSlug &&
      event.therapeutic_area_indication === therapeuticAreaIndicationSlug
  );

  const productEvents = therapeuticAreasContent?.events?.filter(
    (event) =>
      event.brand_slug === brandSlug &&
      event.therapeutic_area_indication === therapeuticAreaIndicationSlug
  );

  const productArticles = therapeuticAreasContent?.articles.filter(
    (article) =>
      article.brand_slug === brandSlug &&
      article.therapeutic_area_indication === therapeuticAreaIndicationSlug &&
      (article.type === "text" || article.type === "material")
  );

  // console.log(therapeuticAreasContent?.articles.filter((article) => article.type === "material"));

  const productVideos = therapeuticAreasContent?.articles.filter(
    (article) =>
      article.brand_slug === brandSlug &&
      article.therapeutic_area_indication === therapeuticAreaIndicationSlug &&
      article.type === "video"
  );

  const productArticlesAndVideos = therapeuticAreasContent?.articles.filter(
    (article) =>
      article.brand_slug === brandSlug &&
      article.therapeutic_area_indication === therapeuticAreaIndicationSlug &&
      (article.type === "text" ||
        article.type === "material" ||
        article.type === "video") &&
      (article.tab_id !== null || article.tab_id !== undefined)
  );

  const productVideosDisplayed =
    !productEventsFuture || productEventsFuture?.length === 0 ? 4 : 2;

  const productArticlesDisplayed =
    !productEventsFuture || productEventsFuture?.length === 0 ? 3 : 2;

  const TabPanelAbout = () => {
    return (
      <TabPanel value={productTab} index={0}>
        <Grid container columnSpacing={4} rowSpacing={2}>
          {productEventsFuture?.length > 0 && (
            <Grid item xs={12}>
              <Box>
                <EventBox event={productEventsFuture[0]} isPromo />
              </Box>
              <Box>
                <Button
                  variant="text"
                  endIcon={<ArrowLongRightIcon width={20} />}
                  component={RouterLink}
                  sx={{ marginTop: 1, color: "secondary.darker" }}
                  to={`/therapeutic-areas/${therapeuticAreaSlug}/${therapeuticAreaIndicationSlug}/${brandSlug}/events`}
                >
                  Vezi toate
                </Button>
              </Box>
            </Grid>
          )}
          {productVideos?.length > 0 && (
            <Grid item xs={12} lg={7}>
              <Typography
                color="primary.black"
                component="p"
                sx={{
                  fontWeight: 400,
                  marginBottom: "0.5rem !important",
                  fontSize: { xs: "1rem" },
                }}
              >
                Video
              </Typography>
              <Grid container spacing={2} alignItems="stretch">
                {productVideos.map(
                  (video, index) =>
                    index < productVideosDisplayed && (
                      <Grid item xs={12} sm={6} key={`video-${index}`}>
                        <VideoBox video={video} isWhite isSmall />
                      </Grid>
                    )
                )}
              </Grid>
              {productVideos?.length >= productVideosDisplayed && (
                <Button
                  variant="text"
                  endIcon={<ArrowLongRightIcon width={20} />}
                  component={RouterLink}
                  sx={{ marginTop: 1, color: "secondary.darker" }}
                  to={`/therapeutic-areas/${therapeuticAreaSlug}/${therapeuticAreaIndicationSlug}/${brandSlug}/videos`}
                >
                  Vezi toate
                </Button>
              )}
            </Grid>
          )}
          {productArticles?.length > 0 && (
            <Grid item xs={12} lg={5}>
              <Typography
                color="primary.black"
                component="p"
                sx={{
                  fontWeight: 400,
                  marginBottom: "0.5rem !important",
                  fontSize: { xs: "1rem" },
                }}
              >
                Articole
              </Typography>
              <Stack gap={2}>
                {productArticles.map(
                  (article, index) =>
                    index < productArticlesDisplayed && (
                      <Box key={`article-${index}`}>
                        <ArticleBox
                          article={article}
                          isWhite
                          isHorizontal={windowWidth >= 600}
                        />
                      </Box>
                    )
                )}
              </Stack>
              {productArticles?.length >= productArticlesDisplayed && (
                <Button
                  variant="text"
                  endIcon={<ArrowLongRightIcon width={20} />}
                  component={RouterLink}
                  sx={{ marginTop: 1, color: "secondary.darker" }}
                  to={`/therapeutic-areas/${therapeuticAreaSlug}/${therapeuticAreaIndicationSlug}/${brandSlug}/articles`}
                >
                  Vezi toate
                </Button>
              )}
            </Grid>
          )}
        </Grid>
      </TabPanel>
    );
  };

  const TabPanelArticles = ({
    tabID = null,
    excludeCustomTabsContent = false,
  }) => {
    return tabID === null ? (
      productArticles.find(
        (article) => article.tab_id === null || article.tab_id === undefined
      ) && (
        <TabPanel value={productTab} index={1}>
          <Grid container spacing={4}>
            {productArticles
              .filter((article) =>
                excludeCustomTabsContent !== null
                  ? article.tab_id === null || article.tab_id === undefined
                  : true
              )
              .map((article, index) => (
                <Grid item xs={12} sm={6} lg={4} key={`article-${index}`}>
                  <ArticleBox article={article} isWhite isDetailed />
                </Grid>
              ))}
          </Grid>
        </TabPanel>
      )
    ) : (
      <TabPanel
        value={productTab}
        index={product.custom_tabs === 1 ? tabID + 3 : tabID - 1}
      >
        <Grid container spacing={4}>
          {productArticlesAndVideos.filter(
            (article) => article.tab_id === tabID
          ).length ? (
            productArticlesAndVideos
              .filter((article) => article.tab_id === tabID)
              .map((article, index) => (
                <Grid item xs={12} sm={6} lg={4} key={`article-${index}`}>
                  {article.type === "video" ? (
                    <VideoBox video={article} isWhite />
                  ) : (
                    <ArticleBox article={article} isWhite isDetailed />
                  )}
                </Grid>
              ))
          ) : (
            <Grid item xs={12}>
              <Typography>Pagină în construcție.</Typography>
            </Grid>
          )}
        </Grid>
      </TabPanel>
    );
  };

  const TabPanelVideos = ({ excludeCustomTabsContent = false }) => {
    // console.log("productTab", productTab);
    // console.log("productVideos", productVideos);
    return (
      productVideos.find(
        (video) => video.tab_id === null || video.tab_id === undefined
      ) && (
        <TabPanel value={productTab} index={2}>
          <Grid container spacing={4} alignItems="stretch">
            {productVideos
              .filter((video) =>
                excludeCustomTabsContent !== null
                  ? video.tab_id === null || video.tab_id === undefined
                  : true
              )
              .map((video, index) => (
                <Grid item xs={12} sm={6} key={`video-${index}`}>
                  <VideoBox video={video} isWhite />
                </Grid>
              ))}
          </Grid>
        </TabPanel>
      )
    );
  };

  const TabPanelEvents = () => {
    return (
      productEvents?.length > 0 && (
        <TabPanel value={productTab} index={3}>
          <Grid container spacing={4} alignItems="stretch">
            {productEvents.map((event, index) => (
              <Grid item xs={12} lg={6} key={`event-${index}`}>
                <EventBox event={event} isWhite />
              </Grid>
            ))}
          </Grid>
        </TabPanel>
      )
    );
  };

  const productSidebarRef = useRef(null);
  const [sidebarWidth, setSidebarWidth] = useState(null); // Initial width

  useEffect(() => {
    const handleResize = () => {
      // console.log("productSidebarRef.current", productSidebarRef.current);
      if (productSidebarRef.current) {
        setSidebarWidth(productSidebarRef.current.clientWidth - 32);
      }
    };
    handleResize();
    window.setTimeout(() => {
      handleResize();
    }, 500);

    // Add event listener on component mount
    window.addEventListener("resize", handleResize);

    // Cleanup function to remove listener on unmount
    return () => window.removeEventListener("resize", handleResize);
  }, [productSidebarRef]); // Dependency array to trigger recalculation on ref changes

  const { windowWidth } = useWindowAspectRatio();
  const theme = useTheme();
  // const isDesktop = useMediaQuery(theme.breakpoints.up("lg"));

  return therapeuticAreasContent && contentSlugs && product !== null ? (
    product ? (
      <Box
        sx={{
          background: {
            xs: "linear-gradient(180deg, transparent 159px, rgba(244,245,247,1) 159px)",
            sm: "linear-gradient(180deg, transparent 219px, rgba(244,245,247,1) 219px)",
          },
          minHeight: "100%",
          display: "flex",
          flexDirection: "column",
          alignItems: "stretch",
          justifyContent: "center",
        }}
      >
        <Box
          sx={{
            position: "absolute",
            left: 0,
            right: 0,
            top: { xs: 60, md: 0 },
            height: { xs: 160, sm: 240, md: 280 },
            background:
              brandSlug === getImage(`banner-${brandSlug}`)
                ? `url(${getImage(
                    `banner-${brandSlug}`
                  )}) #0c0820 no-repeat center`
                : "transparent",
            backgroundSize: "cover",
          }}
        >
          <video
            key={`video-${
              product?.own_banner === 1
                ? windowWidth > 1200
                  ? "desktop"
                  : windowWidth > 820
                  ? "tablet"
                  : "mobile"
                : "therapeutic-area"
            }`}
            muted
            loop
            autoPlay
            playsInline
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              objectFit: "cover",
              objectPosition:
                product?.own_banner === 1
                  ? windowWidth > 820
                    ? "center right"
                    : "center"
                  : "center right",
            }}
          >
            <source
              src={`/assets/images/products/banners/${
                product?.own_banner === 1
                  ? product.slug +
                    (windowWidth > 1200
                      ? "-desktop"
                      : windowWidth > 820
                      ? "-tablet"
                      : "-mobile")
                  : therapeuticAreaSlug
              }.mp4`}
              type="video/mp4"
            />
            Your browser does not support the video tag.
          </video>
        </Box>
        <Container
          maxWidth="xl"
          sx={{
            position: "relative",
            paddingTop: {
              xs: "220px",
              sm: "200px",
              md: "80px",
            },
            paddingBottom: {
              xs: "20px",
              sm: "140px",
              md: "120px",
              lg: "100px",
            },
            height: windowWidth >= 1280 ? "100%" : "auto",
            minHeight: windowWidth >= 1280 ? "100%" : "auto",
            flexGrow: 1,
            display: "flex",
            flexDirection: "column",
            alignItems: "stretch",
            justifyContent: "center",
          }}
        >
          <Box
            display={{ xs: "block", sm: "none" }}
            textAlign="center"
            marginTop={-6}
            marginBottom={2}
          >
            <Button
              component={RouterLink}
              variant="text"
              startIcon={<ArrowLongLeftIcon width={20} />}
              to={`/therapeutic-areas/${therapeuticAreaSlug}/${therapeuticAreaIndicationSlug}`}
              sx={{ fontSize: "0.75rem", color: "primary.link" }}
            >
              Înapoi la {therapeuticAreaIndication.title}
            </Button>
          </Box>
          <Grid
            container
            spacing={4}
            height={windowWidth >= 1280 ? "100%" : "auto"}
            flexGrow={1}
            alignItems="stretch"
            marginBottom={4}
          >
            <Grid item xs={12} md={4} lg={3} ref={productSidebarRef}>
              <Box
                sx={{
                  height: windowWidth >= 1280 ? "calc(100vh - 270px)" : "auto",
                  position:
                    sidebarWidth === null || windowWidth < 1280
                      ? "relative"
                      : "fixed",
                  width: sidebarWidth || "100%",
                  marginTop: 0,
                  borderRadius: "0.75rem",
                  padding: 3,
                  background: theme.palette.primary.main,
                  color: "primary.black",
                  boxShadow: "0px 0px 25px 0px rgba(0, 0, 0, 0.20)",
                }}
              >
                <Stack gap={3} height="100%">
                  <Box>
                    <Stack
                      gap={{
                        xs: 1,
                        sm: 2,
                        md: 1,
                      }}
                      alignItems="center"
                      direction={{
                        xs: "column",
                        sm: "row",
                        md: "column",
                      }}
                    >
                      <Box
                        width={150}
                        minWidth={{
                          xs: "50%",
                          sm: 130,
                          md: "75%",
                        }}
                        paddingTop={{
                          xs: 2,
                          sm: 2,
                          md: 4,
                        }}
                        paddingBottom={2}
                      >
                        <Icon
                          name={`/assets/images/products/color/${product.slug}`}
                          fullPath={true}
                          height="100%"
                          objectFit="contain"
                        />
                      </Box>
                      <Stack gap={1} width="100%" alignItems="center">
                        {product.external_url && (
                          <Stack width="100%" gap={1.5}>
                            {product.external_url
                              .split(";")
                              .map((ext_url, index) => (
                                <ButtonExternalLink
                                  key={`ext_url_${index}`}
                                  fullWidth
                                  target="_blank"
                                  href={ext_url}
                                  variant="contained"
                                  color="primary"
                                  endIcon={<ArrowLongRightIcon width={20} />}
                                >
                                  {ext_url
                                    .replace("http://", "")
                                    .replace("https://", "")}
                                </ButtonExternalLink>
                              ))}
                          </Stack>
                        )}
                        <Box display={{ xs: "none", sm: "block" }}>
                          <Button
                            component={RouterLink}
                            variant="text"
                            startIcon={<ArrowLongLeftIcon width={20} />}
                            to={`/therapeutic-areas/${therapeuticAreaSlug}/${therapeuticAreaIndicationSlug}`}
                            sx={{ fontSize: "0.75rem", color: "primary.link" }}
                          >
                            Înapoi la {therapeuticAreaIndication.title}
                          </Button>
                        </Box>
                      </Stack>
                    </Stack>
                  </Box>
                  <Box
                    flexGrow={1}
                    sx={{
                      overflowY: "auto",
                      ...scrollbarStyles,
                    }}
                  >
                    {/* <Box marginBottom={2}>
                      <Typography
                        variant="body3"
                        component="p"
                        sx={{ lineHeight: "1.125rem" }}
                      >
                        {product.title}
                      </Typography>
                    </Box>
                    <Typography
                      variant="body3"
                      component="p"
                      sx={{ lineHeight: "1.125rem" }}
                      dangerouslySetInnerHTML={{
                        __html: articleContentSanitized(product.description),
                      }}
                    />
                  */}
                    <Typography
                      variant="body3"
                      component="p"
                      sx={{ lineHeight: "1.125rem" }}
                      dangerouslySetInnerHTML={{
                        __html: articleContentSanitized(
                          product.sidebar_description
                        ),
                      }}
                    />
                  </Box>
                  {product.approval_code_sidebar_description && (
                    <Box>
                      <Typography
                        variant="body3"
                        sx={{
                          marginBottom: 0,
                        }}
                      >
                        {product.approval_code_sidebar_description}
                      </Typography>
                    </Box>
                  )}
                  <Box>
                    <Stack
                      gap={2}
                      flexWrap={{
                        xs: "nowrap",
                        sm: "wrap",
                        md: "nowrap",
                      }}
                      direction={{
                        xs: "column",
                        sm: "row",
                        md: "column",
                      }}
                    >
                      {product.rcp_url?.length > 0 && (
                        <Box>
                          <Grid container spacing={2}>
                            {product.rcp_url.map((rcp, index) => (
                              <Grid
                                item
                                key={`RCP-${index + 1}`}
                                xs={product.rcp_url?.length === 1 ? 12 : 6}
                              >
                                <ProductButton
                                  label={
                                    rcp.rcp_name
                                      ? rcp.rcp_name
                                      : product.rcp_url.length > 1
                                      ? `RCP ${index + 1}`
                                      : "RCP"
                                  }
                                  href={rcp.url}
                                />
                              </Grid>
                            ))}
                          </Grid>
                        </Box>
                      )}
                      {product.therapeutic_protocol_url && (
                        <Box>
                          <ProductButton
                            label="Protocol Terapeutic"
                            href={product.therapeutic_protocol_url}
                          />
                        </Box>
                      )}
                      {product.prescription_form_url &&
                        product.prescription_form_url.map(
                          (prescription, index) => (
                            <Box key={`prescription-${index + 1}`}>
                              <ProductButton
                                label={
                                  prescription.prescription_name ||
                                  "Formular prescriere"
                                }
                                href={prescription.url}
                              />
                            </Box>
                          )
                        )}
                    </Stack>
                  </Box>
                </Stack>
              </Box>
            </Grid>
            <Grid item xs={12} md={8} lg={9}>
              <Box
                paddingTop={{ xs: 0, md: 19 }}
                sx={{ color: "primary.black" }}
              >
                {productCustomTabs ? (
                  product.custom_tabs !== 1 ? (
                    <>
                      <Box
                        sx={{
                          borderBottom: 1,
                          borderColor: "secondary.lighter",
                        }}
                      >
                        <Tabs
                          value={productTab}
                          onChange={changeProductTab}
                          aria-label="Tab-uri produs"
                          sx={{
                            minHeight: 38,
                            "& .MuiTabs-indicator": {
                              background: theme.palette.secondary.darker,
                            },
                          }}
                          variant="scrollable"
                          scrollButtons="auto"
                        >
                          {productCustomTabs.map((tab, index) => (
                            <Tab
                              key={`tab-${index}`}
                              label={tab.title}
                              value={index}
                              {...a11yProps(index)}
                            />
                          ))}
                        </Tabs>
                      </Box>
                      <TabPanelArticles
                        tabID={parseInt(productCustomTabs[productTab].id)}
                      />
                    </>
                  ) : (
                    <>
                      <Box
                        sx={{
                          borderBottom: 1,
                          borderColor: "secondary.lighter",
                        }}
                      >
                        <Tabs
                          value={productTab}
                          onChange={changeProductTab}
                          aria-label="Tab-uri produs"
                          sx={{
                            minHeight: 38,
                            "& .MuiTabs-indicator": {
                              background: theme.palette.secondary.darker,
                            },
                          }}
                          variant="scrollable"
                          scrollButtons="auto"
                        >
                          <Tab label="Despre" value={0} {...a11yProps(0)} />
                          {productArticles.find(
                            (article) =>
                              article.tab_id === null ||
                              article.tab_id === undefined
                          ) && (
                            <Tab label="Articole" value={1} {...a11yProps(1)} />
                          )}
                          {productVideos.find(
                            (video) =>
                              video.tab_id === null ||
                              video.tab_id === undefined
                          ) && (
                            <Tab label="Video" value={2} {...a11yProps(2)} />
                          )}
                          {productEvents?.length > 0 && (
                            <Tab
                              label="Evenimente"
                              value={3}
                              {...a11yProps(3)}
                            />
                          )}
                          {productCustomTabs.map((tab, index) => (
                            <Tab
                              key={`tab-${index + 4}`}
                              label={tab.title}
                              value={index + 4}
                              {...a11yProps(index + 4)}
                            />
                          ))}
                        </Tabs>
                      </Box>
                      {productArticles?.length === 0 &&
                        productVideos?.length === 0 &&
                        productEvents?.length === 0 && (
                          <Box mt={3}>Pagină în construcție.</Box>
                        )}
                      <TabPanelAbout />
                      <TabPanelArticles excludeCustomTabsContent />
                      <TabPanelVideos excludeCustomTabsContent />
                      <TabPanelEvents />
                      {productCustomTabs[productTab - 4]?.id && (
                        <TabPanelArticles
                          tabID={parseInt(productCustomTabs[productTab - 4].id)}
                        />
                      )}
                    </>
                  )
                ) : (
                  <>
                    <Box
                      sx={{ borderBottom: 1, borderColor: "secondary.lighter" }}
                    >
                      <Tabs
                        value={productTab}
                        onChange={changeProductTab}
                        aria-label="Tab-uri produs"
                        sx={{
                          minHeight: 38,
                          "& .MuiTabs-indicator": {
                            background: theme.palette.secondary.darker,
                          },
                        }}
                        variant="scrollable"
                        scrollButtons="auto"
                      >
                        <Tab label="Despre" value={0} {...a11yProps(0)} />
                        {productArticles?.length > 0 && (
                          <Tab label="Articole" value={1} {...a11yProps(1)} />
                        )}
                        {productVideos?.length > 0 && (
                          <Tab label="Video" value={2} {...a11yProps(2)} />
                        )}
                        {productEvents?.length > 0 && (
                          <Tab label="Evenimente" value={3} {...a11yProps(3)} />
                        )}
                      </Tabs>
                    </Box>
                    {productArticles?.length === 0 &&
                      productVideos?.length === 0 &&
                      productEvents?.length === 0 && (
                        <Box mt={3}>Pagină în construcție.</Box>
                      )}

                    <TabPanelAbout />
                    <TabPanelArticles />
                    <TabPanelVideos />
                    <TabPanelEvents />
                  </>
                )}
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box>
    ) : (
      <Error404 />
    )
  ) : null;
};

export default ProductInfo;
