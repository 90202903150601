import React, { useState, useEffect } from "react";
import { Link as RouterLink, useSearchParams } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import {
  Button,
  Typography,
  Box,
  Stack,
  Chip,
  FormControl,
  InputLabel,
  FilledInput,
  InputAdornment,
  IconButton,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import axios from "axios";
import {
  ArrowLongLeftIcon,
  ArrowLongRightIcon,
  ShieldCheckIcon,
  EyeIcon,
  EyeSlashIcon,
} from "@heroicons/react/24/outline";
import { FormErrorMessage, Stepper, LinkText } from "components/common";
import config from "utils/config";
import useUserTracking from "utils/useTracking";

const NewPasswordForm = () => {
  // const { accessToken } = useParams();
  const [searchParams] = useSearchParams();
  const accessToken = searchParams.get("token");
  // console.log(accessToken);

  useUserTracking({
    page: "new-password",
  });

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [currentStep, setCurrentStep] = useState(null);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [currentStep]);

  useEffect(() => {
    const checkActivation = async () => {
      try {
        const response = await axios.get(
          `${config.API_URL}/email/set-new-password?token=${accessToken}`
        );

        if (response.data.message === "Please set your password") {
          setCurrentStep("set-new-password");
        }
      } catch (err) {
        // if (err.response.status === 404) {
        setError(
          "Tokenul de setare parolă nouă nu mai este valid sau este incorect."
        );
        // } else {
        //   setError(config.ERRORS.API_ERROR_LOGIN_CREDENTIALS);
        // }
        // console.log("API Error: ", err);
        setCurrentStep("token-invalid");
      }
    };
    checkActivation();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  // SEND EMAIL

  const handleNewPassword = async (values) => {
    // console.log("VALUES", values);
    setCurrentStep("acceptance");
  };
  const formikNewPassword = useFormik({
    initialValues: {
      password: "",
      password_confirm: "",
    },
    validationSchema: Yup.object({
      password: Yup.string()
        .required("Parola este obligatorie.")
        // .matches(
        //   /^(?=.*[A-Z])(?=.*[0-9])(?=.*[#+=@!^&%]).{8,}$/,
        //   "Parola trebuie să conțină cel puțin 8 caractere, o literă mare, o cifră și un caracter special."
        // )
        // .matches(
        //   /^(?=.*[A-Z])(?=.*[0-9])(?=.*[#+=@!^&$%]).*$/,
        //   "Parola introdusă trebuie să conțină cel puțin o literă mare, o cifră și un simbol special. Vă rugăm să încercați din nou."
        // ),
        .matches(
          /^(?=.*[A-Z])(?=.*[0-9])(?=.*[!#$%&'()*+,-./:;<=>?@[\]^_`{|}~]).{8,}$/,
          "Parola introdusă trebuie să conțină cel puțin 8 caractere, o literă mare, o cifră și un simbol special. Vă rugăm să încercați din nou."
        ),
      password_confirm: Yup.string()
        .required("Confirmarea parolei este obligatorie.")
        .oneOf([Yup.ref("password"), null], "Parola introdusă este diferită."),
    }),
    onSubmit: handleNewPassword,
  });

  const [showPassword1, setShowPassword1] = useState(false);
  const [showPasswordConfirm, setShowPasswordConfirm] = useState(false);
  const handleClickShowPassword1 = () => setShowPassword1((show) => !show);
  const handleClickShowPasswordConfirm = () =>
    setShowPasswordConfirm((show) => !show);
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const goToStep1 = () => {
    setCurrentStep("set-new-password");
    setError("");
    formikNewPassword.values.password = "";
    formikNewPassword.values.password_confirm = "";
  };

  // ACCEPTANCE
  const handleAcceptance = async (values) => {
    // console.log("VALUES", values);
    setLoading(true);

    try {
      const response = await axios.post(
        `${config.API_URL}/email/set-new-password/${accessToken}`,
        {
          user_password: formikNewPassword.values.password,
          user_password_confirmation: formikNewPassword.values.password_confirm,
          user_agreements: true,
        }
      );

      if (response.data.message === "Password changed successfully") {
        setError("");
        setCurrentStep("password-changed");
        setLoading(false);
      }
    } catch (err) {
      if (
        err.response.status === 422 &&
        err.response.message === "Password validation failed"
      ) {
        setError(
          "Parolele introduse nu sunt identice. Vă rugăm să încercați din nou."
        );
      } else {
        setError(config.ERRORS.API_ERROR_LOGIN_CREDENTIALS);
      }
    }
  };
  const formikAcceptance = useFormik({
    initialValues: {
      acceptGDPR: false,
      acceptTerms: false,
      acceptConfidentiality: false,
      acceptMarketing: false,
    },
    validationSchema: Yup.object({
      acceptGDPR: Yup.boolean().oneOf(
        [true],
        "Trebuie să acceptați nota de informare."
      ),
      acceptTerms: Yup.boolean().oneOf(
        [true],
        "Trebuie să acceptați termenii și condițiile."
      ),
      acceptConfidentiality: Yup.boolean().oneOf(
        [true],
        "Trebuie să acceptați politica de confidențialitate."
      ),
      acceptMarketing: Yup.boolean().oneOf(
        [true],
        "Trebuie să acceptați acordul de marketing."
      ),
    }),
    onSubmit: handleAcceptance,
    // validateOnBlur: false,
    // validateOnChange: false,
  });

  const onChangeCheckbox = (e, checkboxName) => {
    formikAcceptance.setFieldValue(checkboxName, e.target.checked);
    window.setTimeout(() => {
      formikAcceptance.setTouched({
        ...formikAcceptance.touched,
        [checkboxName]: true,
      });
      formikAcceptance.validateField(checkboxName);
      // console.log(
      //   e.target.checked,
      //   formikAcceptance.touched,
      //   formikAcceptance.errors
      // );
    }, 10);
  };

  return (
    <div>
      {currentStep === "token-invalid" && (
        <>
          <Box
            sx={{
              marginBottom: 7,
              textAlign: {
                xs: "center",
                md: "left",
              },
            }}
          >
            <Typography variant="h1" marginBottom={4}>
              Setare parolă nouă
            </Typography>
          </Box>
          <Box
            sx={{
              textAlign: {
                xs: "center",
                md: "left",
              },
            }}
          >
            <Typography>{error}</Typography>
          </Box>
          <Box
            marginTop={4}
            sx={{
              textAlign: {
                xs: "center",
                md: "left",
              },
            }}
          >
            <Button
              variant="text"
              startIcon={<ArrowLongLeftIcon width={20} />}
              to="/login"
              sx={{ marginBottom: 2.5 }}
              component={RouterLink}
            >
              Înapoi la Autentificare
            </Button>
          </Box>
        </>
      )}
      {currentStep === "set-new-password" && (
        <>
          <Box
            sx={{
              marginBottom: 7,
              textAlign: {
                xs: "center",
                md: "left",
              },
            }}
          >
            <Typography variant="h1" marginBottom={4}>
              Setare parolă nouă
            </Typography>
          </Box>
          <Box sx={{ marginBottom: 5 }}>
            <Stepper
              activeStep={currentStep === "set-new-password" ? 0 : 1}
              steps={["Setare parolă", "Acord"]}
            />
          </Box>
          <Box marginBottom={2}>
            <Typography variant="body2">
              Pentru activarea contului vă rugăm să setați o parolă completând
              câmpurile dedicate. Aceasta trebuie să conțină:
            </Typography>
          </Box>
          <Box marginBottom={7}>
            <Stack
              direction="row"
              alignContent="center"
              alignItems="center"
              columnGap={0.5}
              marginBottom={0.5}
            >
              <Box flexGrow={0}>
                <ShieldCheckIcon
                  width={18}
                  style={{ verticalAlign: "middle", marginTop: -2 }}
                />
              </Box>
              <Box flexGrow={1}>
                <Typography variant="body2">Cel puțin o literă mare</Typography>
              </Box>
              <Box flexGrow={0} width="4rem">
                <Typography variant="body2" component="div">
                  <Chip label="ABC" size="small" sx={{ width: "100%" }} />
                </Typography>
              </Box>
            </Stack>
            <Stack
              direction="row"
              alignContent="center"
              alignItems="center"
              columnGap={0.5}
              marginBottom={0.5}
            >
              <Box flexGrow={0}>
                <ShieldCheckIcon
                  width={18}
                  style={{ verticalAlign: "middle", marginTop: -2 }}
                />
              </Box>
              <Box flexGrow={1}>
                <Typography variant="body2">Cel puțin o cifră</Typography>
              </Box>
              <Box flexGrow={0} width="4rem">
                <Typography variant="body2" component="div">
                  <Chip label="123" size="small" sx={{ width: "100%" }} />
                </Typography>
              </Box>
            </Stack>
            <Stack
              direction="row"
              alignContent="center"
              alignItems="center"
              columnGap={0.5}
              marginBottom={0.5}
            >
              <Box flexGrow={0}>
                <ShieldCheckIcon
                  width={18}
                  style={{ verticalAlign: "middle", marginTop: -2 }}
                />
              </Box>
              <Box flexGrow={1}>
                <Typography variant="body2">
                  Cel puțin un simbol special
                </Typography>
              </Box>
              <Box flexGrow={0} width="4rem">
                <Typography variant="body2" component="div">
                  <Chip label="#+=" size="small" sx={{ width: "100%" }} />
                </Typography>
              </Box>
            </Stack>
          </Box>

          <form onSubmit={formikNewPassword.handleSubmit}>
            <Box
              sx={{
                paddingX: {
                  xs: 0,
                  sm: 2,
                },
              }}
            >
              <FormControl
                fullWidth
                variant="filled"
                sx={{
                  marginBottom: "1rem",
                }}
              >
                <InputLabel htmlFor="password">Introduceți parola</InputLabel>
                <FilledInput
                  id="password"
                  name="password"
                  type={showPassword1 ? "text" : "password"}
                  autoComplete="password"
                  value={formikNewPassword.values.password}
                  onChange={formikNewPassword.handleChange}
                  onBlur={formikNewPassword.handleBlur}
                  error={
                    formikNewPassword.touched.password &&
                    Boolean(formikNewPassword.errors.password)
                  }
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword1}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {showPassword1 ? (
                          <EyeSlashIcon
                            width={24}
                            style={{ color: "primary" }}
                          />
                        ) : (
                          <EyeIcon width={24} style={{ color: "primary" }} />
                        )}
                      </IconButton>
                    </InputAdornment>
                  }
                />
                {formikNewPassword.touched.password && (
                  <FormErrorMessage>
                    {formikNewPassword.errors.password}
                  </FormErrorMessage>
                )}
              </FormControl>
              <FormControl
                fullWidth
                variant="filled"
                sx={{
                  marginBottom: "1rem",
                }}
              >
                <InputLabel htmlFor="password">Confirmați parola</InputLabel>
                <FilledInput
                  id="password_confirm"
                  name="password_confirm"
                  type={showPasswordConfirm ? "text" : "password"}
                  autoComplete="password-confirm"
                  value={formikNewPassword.values.password_confirm}
                  onChange={formikNewPassword.handleChange}
                  onBlur={formikNewPassword.handleBlur}
                  error={
                    formikNewPassword.touched.password_confirm &&
                    Boolean(formikNewPassword.errors.password_confirm)
                  }
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPasswordConfirm}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {showPasswordConfirm ? (
                          <EyeSlashIcon
                            width={24}
                            style={{ color: "primary" }}
                          />
                        ) : (
                          <EyeIcon width={24} style={{ color: "primary" }} />
                        )}
                      </IconButton>
                    </InputAdornment>
                  }
                />
                {formikNewPassword.touched.password_confirm && (
                  <FormErrorMessage>
                    {formikNewPassword.errors.password_confirm}
                  </FormErrorMessage>
                )}
              </FormControl>
              <Box marginTop={5} textAlign="center">
                <Button
                  variant="contained"
                  color="primary"
                  endIcon={<ArrowLongRightIcon width={20} />}
                  disabled={loading}
                  type="submit"
                  sx={{
                    width: {
                      xs: "100%",
                      sm: "auto",
                    },
                  }}
                >
                  Următorul pas
                </Button>
                <Box
                  marginTop={3}
                  paddingBottom={3}
                  sx={{
                    textAlign: {
                      xs: "center",
                      sm: "left",
                    },
                  }}
                >
                  <Button
                    variant="outlined"
                    to="/login"
                    component={RouterLink}
                    disabled={loading}
                  >
                    Anulare
                  </Button>
                </Box>
              </Box>
            </Box>
          </form>
        </>
      )}
      {currentStep === "acceptance" && (
        <>
          <Box
            sx={{ marginBottom: 7, textAlign: { xs: "center", sm: "left" } }}
          >
            <Typography variant="h1" marginBottom={4}>
              Setare parolă nouă
            </Typography>
          </Box>
          <Box sx={{ marginBottom: 5 }}>
            <Stepper
              activeStep={currentStep === "set-new-password" ? 0 : 1}
              steps={["Setare parolă", "Acord"]}
            />
          </Box>
          <form onSubmit={formikAcceptance.handleSubmit}>
            <Box marginBottom={2}>
              <FormControlLabel
                name="acceptGDPR"
                control={
                  <Checkbox
                    size="small"
                    checked={formikAcceptance.values.acceptGDPR}
                    onChange={(e) => onChangeCheckbox(e, "acceptGDPR")}
                  />
                }
                label={
                  <Typography variant="body2">
                    Am citit{" "}
                    <LinkText href={config.DOCUMENTS.GDPR} target="_blank">
                      nota de informare
                    </LinkText>{" "}
                    cu privire la prelucrarea datelor cu caracter personal
                  </Typography>
                }
              />
              {formikAcceptance.touched.acceptGDPR && (
                <FormErrorMessage>
                  {formikAcceptance.errors.acceptGDPR}
                </FormErrorMessage>
              )}
            </Box>
            <Box marginBottom={2}>
              <FormControlLabel
                name="acceptTerms"
                control={
                  <Checkbox
                    size="small"
                    checked={formikAcceptance.values.acceptTerms}
                    onChange={(e) => onChangeCheckbox(e, "acceptTerms")}
                  />
                }
                label={
                  <Typography variant="body2">
                    Sunt de acord cu{" "}
                    <LinkText href={config.DOCUMENTS.TERMS} target="_blank">
                      termenii și condițiile
                    </LinkText>{" "}
                    site-ului aznextgen.ro
                  </Typography>
                }
              />
              {formikAcceptance.touched.acceptTerms && (
                <FormErrorMessage>
                  {formikAcceptance.errors.acceptTerms}
                </FormErrorMessage>
              )}
            </Box>
            <Box marginBottom={2}>
              <FormControlLabel
                name="acceptConfidentiality"
                control={
                  <Checkbox
                    size="small"
                    checked={formikAcceptance.values.acceptConfidentiality}
                    onChange={(e) =>
                      onChangeCheckbox(e, "acceptConfidentiality")
                    }
                  />
                }
                label={
                  <Typography variant="body2">
                    Sunt de acord cu standardele interne privind{" "}
                    <LinkText
                      href={config.DOCUMENTS.CONFIDENTIALITY}
                      target="_blank"
                    >
                      politica de confidențialitate
                    </LinkText>{" "}
                    a site-ului aznextgen.ro
                  </Typography>
                }
              />
              {formikAcceptance.touched.acceptConfidentiality && (
                <FormErrorMessage>
                  {formikAcceptance.errors.acceptConfidentiality}
                </FormErrorMessage>
              )}
            </Box>
            <Box marginBottom={2}>
              <FormControlLabel
                name="acceptMarketing"
                control={
                  <Checkbox
                    size="small"
                    checked={formikAcceptance.values.acceptMarketing}
                  />
                }
                // onBlur={formikAcceptance.handleBlur}
                onChange={formikAcceptance.handleChange}
                label={
                  <Typography variant="body2">
                    Sunt de acord să primesc mesaje de marketing de la compania
                    AstraZeneca prin e-mail și SMS
                  </Typography>
                }
              />
              {formikAcceptance.touched.acceptMarketing && (
                <FormErrorMessage>
                  {formikAcceptance.errors.acceptMarketing}
                </FormErrorMessage>
              )}
            </Box>

            <Box paddingX={2} marginTop={4}>
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
              >
                <Button
                  variant="text"
                  startIcon={<ArrowLongLeftIcon width={20} />}
                  onClick={goToStep1}
                >
                  Înapoi
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  endIcon={<ArrowLongRightIcon width={20} />}
                  disabled={loading}
                  type="submit"
                >
                  Finalizează
                </Button>
              </Stack>
            </Box>
          </form>
          <Box marginTop={4} paddingBottom={3}>
            <Button
              variant="outlined"
              to="/login"
              component={RouterLink}
              disabled={loading}
            >
              Anulare
            </Button>
          </Box>
        </>
      )}
      {currentStep === "password-changed" && (
        <>
          <Box
            sx={{
              marginBottom: 4,
              marginTop: 5,
              textAlign: { xs: "center", sm: "left" },
            }}
          >
            <Typography variant="h1" marginBottom={4}>
              Parola nouă a fost setată
            </Typography>
          </Box>
          <Box
            sx={{
              textAlign: { xs: "center", sm: "left" },
            }}
          >
            <Button
              variant="text"
              endIcon={<ArrowLongRightIcon width={20} />}
              component={RouterLink}
              to="/login"
            >
              Mergeți la Autentificare
            </Button>
          </Box>
        </>
      )}
    </div>
  );
};

export default NewPasswordForm;
